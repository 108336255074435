import React, { useContext, useMemo } from 'react'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { ContactsSectionProps } from './types'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { AppDataContext } from '../../../providers/AppData'

const ContactsSection: React.FC<ContactsSectionProps> = (props) => {
  const { language } = useContext(AppDataContext)
  const { peerRecommendations, totalPeerRecommendations = 0 } = props
  const translate = useTranslateMessage()

  const recommendersString = useMemo(() => {
    const recommendersToShow =
      peerRecommendations
        ?.slice?.(0, 2)
        ?.map((pr) =>
          `${pr?.recommender?.title?.[language]} ${pr?.recommender?.firstName?.[language]} ${pr?.recommender?.lastName?.[language]}`.trim()
        ) ?? []

    const othersTotal = totalPeerRecommendations - recommendersToShow.length
    const recommendersStr = recommendersToShow?.join?.(', ')

    if (!recommendersToShow?.length) {
      return ''
    }

    return translate(othersTotal > 0 ? 'profile.intro.recommenders_with_total' : 'profile.intro.recommenders', {
      total: othersTotal,
      recommenders: recommendersStr
    })
  }, [language, peerRecommendations, totalPeerRecommendations, translate])

  return (
    <>
      <Mobile {...props} recommendersString={recommendersString} />
      <Desktop {...props} recommendersString={recommendersString} />
    </>
  )
}

export default ContactsSection
