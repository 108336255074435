import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react'
import dynamic from 'next/dynamic'
import type { GetStaticPropsContext } from 'next'
import { useRouter } from 'next/router'
import { Grid } from '@hermes/web-components'
import { ProfileContextProvider } from '../../../providers/AppData/Profile'
import OtherTopRatedPractices from '../../../components/profiles/practice/OtherTopRatedPractices'
import {
  ProfileKeyword,
  Statistic,
  PracticeProfile,
  SpecialistProfile,
  ProfileReview,
  ProfilePeerRecommendation,
  Percentage,
  Keywords,
  SpecialistProfileWithAddress
} from '../../../types'
import { Breadcrumb } from '../../../types/breadcrumbs'
import Breadcrumbs from '../../../components/Breadcrumbs'
import {
  getSpecialistBySlug,
  getSpecialistSlotsBySlug,
  getSpecialistReviews,
  getSpecialistLatestReview,
  getSpecialistPeerRecommendations,
  getSpecialistLastPeerRecommendation,
  getNearBy,
  getBreadcrumbByLocationId
} from '../../../api/specialist'
import { SearchReviewsProvider } from '../../../providers/SearchReviews'
import { SearchPeerRecommendationsContextProvider } from '../../../providers/SearchPeerRecommendations'
import SpecialistTrustBanner from '../../../components/profiles/specialist/TrustBanner'
import PageMeta from '../../../components/ProfileMeta'
import withError from '../../../components/withError'
import useSpecialistProfileMeta from '../../../hooks/useSpecialistProfileMeta'
import { transformSpecialistProfileResponse } from '../../../utils/transformSpecialistProfile'
import { transformSpecialistBookingSlots } from '../../../utils/transformSpecialistBookingSlots'
import { checkProfileSlugRedirect } from '../../../utils/checkProfileSlugRedirect'
import SectionWrapper from '../../../components/profiles/SectionWrapper'
import { SpecialistPlans, limitReviews } from '../../../constants/profile'
import { filteringProfileDataByPlan } from '../../../utils/filteringDataByPlan'
import getLanguageFromLocale from '../../../utils/getLanguageFromLocale'
import ContactsSection from '../../../components/SpecialistPage/ContactsSection'
import ProfileAbout from '../../../components/profiles/About'
import { BasicProfileAlert } from '../../../components/profiles/BasicProfileAlert'
import Reviews from '../../../components/profiles/Reviews'
import SpecialistRecommendations from '../../../components/specialist/SpecialistRecommendations'
import SpecialistWorkingHours from '../../../components/specialist/SpecialistWorkingHours'
import MediaCenter from '../../../components/profiles/Media'
import FAQ from '../../../components/profiles/specialist/FAQ'
import OtherTopRated from '../../../components/profiles/specialist/OtherTopRated'
import { BookingCalendarProvider } from '../../../providers/BookingCalendar'
import { BookingSlot } from '../../../types/booking'
import getConfigVariable from '../../../utils/getConfigVariable'
import { useSpecialistPageConfig } from '../../../hooks/useSpecialistPageConfig'
import { themedColor } from '../../../components/profiles/styles'
import returnNextErrorPage from '../../../utils/returnNextErrorPage'
import { breadcrumbsObjectToArray } from '../../../utils/breadcrumbsObjectToArray'
import { ProfileTypes, SearchTypes } from '../../../providers/SearchProfilesPage/types'
import { TENANTS, newSearchTypesPractices } from '../../../constants/global'
import BookingModal from '../../../components/SpecialistPage/BookingModal'
import { AppDataContext } from '../../../providers/AppData'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { getFeatureFlags } from '../../../hooks/useFeatureFlags'
import { transformSearchType } from '../../../utils/transformAutocomplete'
import { withStaticPropsMocks } from '../../../playwright/utils/withMockingProps'
import { useWpaTenant } from '../../../hooks/useWpaTenant'
import { useSpecialistPracticesWithAddress } from '../../../hooks/useSpecialistPracticesWithAddress'
import useScrollEndEffect from '../../../hooks/useScrollEndEffect'

const VideoPlayerModal = dynamic(() => import('../../../components/profiles/VideoPlayerModal'), { ssr: false })

const BookingCalendarModal = dynamic(() => import('../../../components/BookingCalendar/BookingCalendarModal'), {
  ssr: false
})

type SpecialistPageProps = {
  lang: string
  specialist: SpecialistProfileWithAddress
  bookingSlots: BookingSlot[]
  latestReviews: ProfileReview[]
  latestReview: ProfileReview
  totalReviews: number
  topKeyword: ProfileKeyword
  topLocation: {
    label: string
    slug: string
  }
  errorCode?: number
  totalPeerRecommendations: number
  peerRecommendations: ProfilePeerRecommendation[]
  latestPeerRecommendation: ProfilePeerRecommendation
  nearBy?: {
    specialists: SpecialistProfile[]
    practices: PracticeProfile[]
  }
  isUnclaimed: boolean
  isProOrExpertPro: boolean
  isEntry: boolean
  percentage: Percentage
  breadcrumbs: Breadcrumb[]
  keywordNames: Keywords[]
}

const whiteColor = 'common.white'

// todo: fix complexity
// eslint-disable-next-line complexity
function SpecialistMainPage({
  specialist,
  bookingSlots,
  latestReviews,
  keywordNames,
  latestReview,
  totalReviews,
  peerRecommendations,
  latestPeerRecommendation,
  totalPeerRecommendations,
  nearBy,
  percentage,
  topKeyword,
  topLocation,
  breadcrumbs,
  isUnclaimed,
  isProOrExpertPro,
  isEntry
}: SpecialistPageProps) {
  const { language, isRtl } = useContext(AppDataContext)

  const { isWPA } = useWpaTenant()
  const router = useRouter()
  const translate = useTranslateMessage()

  useScrollEndEffect(() => {
    const routerHash = router.asPath.split('#')[1]
    const windowHash = window.location.hash.split('#')[1]
    const hash = routerHash || windowHash

    if (hash) {
      const selectedComponent = document.getElementById(hash)

      if (selectedComponent) {
        selectedComponent.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [router.asPath])

  const isImproveListingVisible = !isWPA && (isUnclaimed || isEntry)

  const address = useMemo(() => {
    if (specialist.address?.geolocation !== null && specialist.type === SearchTypes.healthAndSocialCare) {
      return specialist.address
    }

    return undefined
  }, [specialist.address, specialist.type])

  const pageMeta = useSpecialistProfileMeta(specialist)
  const {
    profileVideo,
    profileName,
    visibilityComponents,
    introVideoAvailable,
    publications,
    specialistTitle,
    specialistSuffix,
    contacts,
    mappedBreadcrumbs,
    pageSchema,
    practicesWithContactDetails,
    reviewStatistic,
    peerRecommendationStatistic,
    mergedStatistic,
    hideBookAppointment,
    hideBookAppointmentButton,
    otherSpecialistsButtonText,
    otherPracticesButtonText,
    otherSpecialistSearchUrl,
    otherPracticesSearchUrl,
    profileAnalytics,
    sortedLanguages,
    bookingCalendarSpecialistProfile,
    bookingCalendarSpecialistPractices,
    bookingCalendarSlots,
    bookingCalendarVisitReasons
  } = useSpecialistPageConfig({
    specialist,
    breadcrumbs,
    isUnclaimed,
    topKeyword,
    topLocation,
    totalReviews,
    bookingSlots,
    address
  })
  const { specialistLocationSectionBackground, buttonBackground, endorsementsBackground } = themedColor()

  const mediaCenterRef = useRef<HTMLDivElement>(null)

  const [showBookingModal, setShowBookingModal] = useState(false)
  const [videoPlayerVisibility, setVideoPlayerVisibility] = useState(false)
  const [bookingModalActiveButton, setBookingModalActiveButton] = useState<string | undefined>(undefined)

  const onlineBookingAvailable = !!bookingSlots?.length

  const toggleBookingModal = useCallback(
    (button?: string, interactionLabel?: string) => {
      if (interactionLabel?.length) {
        window.dataLayer.push({
          event: 'prf_pg_cont_button',
          cont_prf_name: specialist.fullName?.[language],
          cont_prf_type: 'Specialist',
          cont_interaction_label: interactionLabel,
          cont_prf_uuid: specialist?.externalId
        })
      }
      setBookingModalActiveButton(button)
      setShowBookingModal((prev: boolean) => !prev)
    },
    [language, specialist?.externalId, specialist.fullName]
  )

  const handleRouteChange = useCallback(
    (url: string) => {
      if (url === `/${router.locale}${router.asPath}`) {
        window.dataLayer.push({
          event: 'prf_spa_page_change_ready',
          ...profileAnalytics
        })
      }
    },
    [profileAnalytics, router.asPath, router.locale]
  )

  const { filteredPractices } = useSpecialistPracticesWithAddress(specialist.practices)

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        ...profileAnalytics,
        practice_ids: filteredPractices.map((p) => p.externalId) || []
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  return (
    <>
      <PageMeta isProfile {...pageMeta}>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: pageSchema }} />
      </PageMeta>
      <ProfileContextProvider specialist={specialist}>
        <SearchReviewsProvider
          language={language}
          keywordNames={keywordNames}
          reviews={latestReviews}
          currentLocation={breadcrumbs.slice(-1).pop()}
          profileId={specialist.id}
          totalCount={totalReviews}
        >
          <BookingCalendarProvider
            key={specialist.id}
            specialistProfile={bookingCalendarSpecialistProfile}
            toggleBookingModal={toggleBookingModal}
            practices={bookingCalendarSpecialistPractices}
            slots={bookingCalendarSlots}
            visitReasons={bookingCalendarVisitReasons}
            insurances={specialist.insurers}
          >
            <ContactsSection
              endAnchorRef={mediaCenterRef}
              address={address}
              specialist={specialist}
              isUnclaimed={isUnclaimed}
              specialistTitle={specialistTitle}
              specialistSuffix={specialistSuffix}
              isEntry={isEntry}
              introVideoAvailable={introVideoAvailable}
              peerRecommendations={peerRecommendations}
              totalPeerRecommendations={totalPeerRecommendations}
              profileAnalytics={profileAnalytics}
              setVideoPlayerVisibility={setVideoPlayerVisibility}
              onlineBookingAvailable={onlineBookingAvailable}
              latestPeerRecommendation={latestPeerRecommendation}
              latestReview={latestReview}
              contacts={contacts}
              practicesWithContactDetails={practicesWithContactDetails}
              toggleBookingModal={toggleBookingModal}
              hideBookAppointment={hideBookAppointment}
            />

            {visibilityComponents.about && (
              <SectionWrapper withPadding sx={{ backgroundColor: whiteColor }}>
                <Grid container>
                  {isUnclaimed && router.locale !== TENANTS.WPA && (
                    <Grid item lg={7} md={12} xs={12} sx={{ mb: 2 }}>
                      <BasicProfileAlert
                        id={specialist.externalId}
                        slug={specialist.slug}
                        plan={specialist.plan}
                        name={specialist.fullName[language]}
                      />
                    </Grid>
                  )}
                  <Grid item md={12} lg={7} xs={12}>
                    <ProfileAbout
                      isBasic={specialist.basic}
                      about={specialist.about?.[language]}
                      keywords={specialist.keywords}
                      registrationBodies={specialist.registrationBodies}
                      insurers={specialist.insurers}
                      languages={sortedLanguages}
                      consultationFees={specialist.consultationFees}
                      statistic={mergedStatistic}
                      name={profileName}
                      nearBy={nearBy?.specialists}
                      isEntry={isEntry}
                      qualifications={specialist.education?.[language] as string}
                      otherSpecialistsButtonText={otherSpecialistsButtonText}
                      otherSpecialistSearchUrl={otherSpecialistSearchUrl}
                      isUnclaimed={isUnclaimed}
                      publications={publications}
                      isImproveListingVisible={isImproveListingVisible}
                    />
                  </Grid>
                  {specialist.basic && (nearBy?.specialists || nearBy?.practices) && (
                    <>
                      {!isEntry && !isUnclaimed && (
                        <Grid item md={12} lg={7} xs={12} sx={{ mt: 2 }}>
                          <OtherTopRated
                            specialists={nearBy?.specialists}
                            headerText={translate('other.specialsts.headerText')}
                            buttonText={otherSpecialistsButtonText}
                            isRtl={isRtl}
                            shortButtonText={translate('other.specialsts.shortButtonText')}
                            buttonLink={otherSpecialistSearchUrl}
                          />
                        </Grid>
                      )}
                      {router.locale !== TENANTS.WPA && (
                        <Grid item md={12} lg={7} xs={12} sx={{ mt: 2 }}>
                          <OtherTopRatedPractices
                            practices={nearBy?.practices}
                            headerText={translate('other.practices.headerText')}
                            buttonText={otherPracticesButtonText}
                            isRtl={isRtl}
                            shortButtonText={translate('other.practices.shortButtonText')}
                            buttonLink={otherPracticesSearchUrl}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </SectionWrapper>
            )}
            {visibilityComponents.location && (
              <SectionWrapper
                withPadding
                sx={{
                  background: specialistLocationSectionBackground
                }}
                id="locations"
              >
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <SpecialistWorkingHours
                      id={specialist.externalId}
                      address={address}
                      slug={specialist.slug}
                      review={specialist.review}
                      profileName={profileName}
                      plan={specialist.plan}
                      practices={specialist.practices}
                      videoConsultation={specialist.videoConsultation}
                      staticMapImage={specialist.staticMapImage}
                      basic={specialist.basic}
                      hideBookAppointment={hideBookAppointment}
                      hideBookAppointmentButton={hideBookAppointmentButton}
                      onBookAppointment={toggleBookingModal}
                      isUnclaimed={isUnclaimed}
                      onGetInTouch={toggleBookingModal}
                    />
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            {visibilityComponents.reviews && (
              <SectionWrapper withPadding id="reviews">
                <Grid container>
                  <Grid item lg={7} xs={12}>
                    <Reviews
                      id={specialist.externalId}
                      plan={specialist.plan}
                      statistic={reviewStatistic}
                      slug={specialist.slug}
                      isBasic={specialist.basic}
                      name={specialist.fullName[language]}
                      isUnclaimed={isUnclaimed}
                      isProOrExpertPro={isProOrExpertPro}
                      isEntry={isEntry}
                      percentage={percentage}
                    />
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            <SectionWrapper sx={{ backgroundColor: buttonBackground }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={7}>
                  <SpecialistTrustBanner />
                </Grid>
              </Grid>
            </SectionWrapper>
            {visibilityComponents.peerRecommendations && (
              <SectionWrapper withPadding sx={{ backgroundColor: endorsementsBackground }} id="endorsements">
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <SearchPeerRecommendationsContextProvider
                      recommendations={peerRecommendations}
                      totalCount={totalPeerRecommendations}
                      profileId={specialist.id}
                    >
                      <SpecialistRecommendations statistics={peerRecommendationStatistic} />
                    </SearchPeerRecommendationsContextProvider>
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            {visibilityComponents.media && (
              <section id="media">
                <div ref={mediaCenterRef}>
                  <MediaCenter
                    isBasic={specialist.basic}
                    profileName={profileName}
                    profileImage={specialist.images?.logo}
                    profileAnalytics={profileAnalytics}
                    isUnclaimed={isUnclaimed}
                    posts={specialist.media}
                    setVideoPlayerVisibility={setVideoPlayerVisibility}
                  />
                </div>
              </section>
            )}
            {visibilityComponents.faq && (
              <SectionWrapper withPadding sx={{ backgroundColor: whiteColor }} id="faq">
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <FAQ specialist={specialist} onGetInTouch={() => toggleBookingModal('all', 'get in touch')} />
                  </Grid>
                  <Grid item xs={12} lg={5} />
                </Grid>
              </SectionWrapper>
            )}
            {Boolean(breadcrumbs.length) && breadcrumbs.length >= 1 && router.locale !== TENANTS.WPA && (
              <SectionWrapper withPadding sx={{ backgroundColor: 'background.light' }} id="breadcrumbs">
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <Breadcrumbs items={mappedBreadcrumbs} />
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            <BookingModal
              showBookingModal={showBookingModal}
              toggleBookingModal={toggleBookingModal}
              bookingModalActiveButton={bookingModalActiveButton}
              specialist={specialist}
            />
            {onlineBookingAvailable && <BookingCalendarModal />}
          </BookingCalendarProvider>
        </SearchReviewsProvider>
      </ProfileContextProvider>

      {profileVideo && videoPlayerVisibility && (
        <VideoPlayerModal
          open
          specialistId={specialist.id}
          mediaId={profileVideo?.id}
          url={profileVideo?.link}
          onClose={() => setVideoPlayerVisibility(false)}
        />
      )}
    </>
  )
}

// todo: fix complexity
// eslint-disable-next-line complexity
export const getStaticProps = withStaticPropsMocks(async (context: GetStaticPropsContext) => {
  const lang = context.locale as string
  const slug = context.params?.slug as string
  const language = getLanguageFromLocale(lang)
  const slugToRedirect = checkProfileSlugRedirect(slug)
  const { alternativeSearchEnabled } = getFeatureFlags()

  if (slugToRedirect) {
    return {
      redirect: {
        destination: `/${lang}/specialist/${slugToRedirect}`,
        statusCode: 301
      }
    }
  }

  const specialistResponse = await getSpecialistBySlug({
    lang,
    slug
  })

  if (specialistResponse.statusCode || specialistResponse.message) {
    return returnNextErrorPage({ statusCode: specialistResponse.statusCode, avoidNextBehaviour: lang === TENANTS.WPA })
  }

  const { result: specialist } = specialistResponse

  const topLevelKeyword =
    specialist.keywords.find((keyword) => keyword.isTopLevelChild && keyword.keywordType === 'specialty') || null

  let topStat: Statistic | null = null

  specialist.statistic.forEach((obj) => {
    if (!topStat || obj.tagCount > topStat.tagCount) {
      topStat = obj
    }
  })

  const topKeyword: ProfileKeyword | null =
    topLevelKeyword || specialist.keywords.find((k) => k.keywordId === topStat?.tagId) || null

  const sortedPractices = [...specialist.practices]
    .sort((a, b) => b.reviews.reviewsTotal - a.reviews.reviewsTotal)
    .sort((a, b) => b.practiceSortOrder - a.practiceSortOrder)
    .filter((x) => x.slug !== 'video-consultation')

  const LocationId = specialist?.address?.LocationId || sortedPractices?.[0]?.address?.LocationId
  let breadcrumbs: Breadcrumb[] = []

  if (typeof LocationId === 'number') {
    const breadcrumbsResponse = await getBreadcrumbByLocationId({ lang, id: LocationId })

    if (!('statusCode' in breadcrumbsResponse)) {
      const breadcrumbsProps =
        alternativeSearchEnabled &&
        lang !== TENANTS.WPA &&
        specialist.type &&
        newSearchTypesPractices.includes(specialist.type as SearchTypes)
          ? {
              objForTransform: breadcrumbsResponse,
              locale: lang,
              language,
              topKeyword: null,
              profileType: transformSearchType(specialist.type)
            }
          : {
              objForTransform: breadcrumbsResponse,
              locale: lang,
              language,
              topKeyword,
              profileType: ProfileTypes.specialists
            }

      breadcrumbs = topKeyword ? breadcrumbsObjectToArray(breadcrumbsProps) : []
    }
  }

  const isBasic = specialist.basic
  const isProOrExpertPro =
    specialist?.plan?.includes(SpecialistPlans.EXPERT) || specialist?.plan?.includes(SpecialistPlans.EXPERT_PRO)
  const isUnclaimed =
    specialist.plan?.includes(SpecialistPlans.UNCLAIMED) || specialist.plan?.includes(SpecialistPlans.NO_PLAN)
  const isEntry = specialist.plan?.includes(SpecialistPlans.ENTRY)

  const promises: Promise<any>[] = [
    getSpecialistPeerRecommendations({
      id: specialist.id,
      lang,
      limit: 4
    }),
    getSpecialistLastPeerRecommendation({
      id: specialist.id,
      lang
    })
  ]

  if (isBasic) {
    promises.push(
      getNearBy({
        slug,
        lang
      })
    )
  } else {
    promises.push(
      ...[
        getSpecialistReviews({
          id: specialist.id,
          tenant: lang,
          language,
          limit: limitReviews
        }),
        getSpecialistLatestReview({
          id: specialist.id,
          tenant: lang,
          language
        })
      ]
    )
  }

  const filteredSpecialistData = filteringProfileDataByPlan({ specialist }) as SpecialistProfile
  const [{ result: peerRecommendations, totalCount: totalPeerRecommendations }, latestPeerRecommendation, ...restData] =
    await Promise.all(promises)

  const topLocationFromBreadcrumbs = Array.from(breadcrumbs).reverse()[0] || null

  let bookingSlots: Array<BookingSlot> = []

  const stage = getConfigVariable('STAGE')

  if (stage !== 'production' || (stage === 'production' && lang === 'wpa')) {
    const bookingSlotsResponse = await getSpecialistSlotsBySlug({ lang, slug })

    if ('availability' in bookingSlotsResponse) {
      bookingSlots = transformSpecialistBookingSlots(bookingSlotsResponse)
    }
  }

  const data = {
    lang,
    specialist: transformSpecialistProfileResponse(filteredSpecialistData, language),
    bookingSlots,
    slug: context.params?.slug,
    breadcrumbs,
    peerRecommendations: peerRecommendations || null,
    totalPeerRecommendations: totalPeerRecommendations || 0,
    keywordNames: restData[0]?.keywordNames || null,
    topKeyword,
    topLocation: topLocationFromBreadcrumbs || null,
    latestPeerRecommendation:
      (Array.isArray(latestPeerRecommendation) ? latestPeerRecommendation[0] : latestPeerRecommendation) || null,
    isUnclaimed,
    isProOrExpertPro,
    isEntry
  }
  if (isBasic) {
    const [{ specialists, practices }] = restData

    return {
      props: {
        ...data,
        nearBy: { specialists: specialists?.data || null, practices: practices?.data || null }
      },
      revalidate: 60 * 60 * 8 // 8h
    }
  }
  const [{ result: reviews, totalCount }, latestReview] = restData

  return {
    props: {
      ...data,
      latestReviews: Array.isArray(reviews) ? reviews : [],
      totalReviews: totalCount,
      topKeyword,
      latestReview: Array.isArray(latestReview) ? latestReview[0] || null : latestReview || null,
      percentage: restData[0]?.percentage || null
    },
    revalidate: 60 * 60 * 8 // 8h
  }
}, 'specialist/:slug')

export function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking'
  }
}

const SpecialistPage = withError<SpecialistPageProps>(SpecialistMainPage)

export default SpecialistPage
