import React from 'react'
import dynamic from 'next/dynamic'
import { useBookingCalendar } from '../../../providers/BookingCalendar'
import { SpecialistProfile } from '../../../types'

const BookAppointmentModal = dynamic(() => import('../../profiles/ProfileModal/BookAppointmentModal'), {
  ssr: false
})

type BookingModalProps = {
  showBookingModal?: boolean
  toggleBookingModal: () => void
  bookingModalActiveButton?: string
  specialist: SpecialistProfile
}

const BookingModal = ({
  showBookingModal,
  toggleBookingModal,
  bookingModalActiveButton,
  specialist
}: BookingModalProps) => {
  const bookingCalendarContext = useBookingCalendar()

  return showBookingModal ? (
    <BookAppointmentModal
      isOpen
      onClose={toggleBookingModal}
      bookingModalActiveButton={bookingModalActiveButton}
      specialist={specialist}
      bookingCalendarContext={bookingCalendarContext}
      hideProfileHeader={true}
    />
  ) : null
}

export default BookingModal
