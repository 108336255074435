import React, { useMemo, useContext, useState, useEffect, useCallback, FC } from 'react'
import NextLink from 'next/link'
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Chip,
  styledComponent,
  Link,
  Box,
  CheckboxCircle,
  useTheme
} from '@hermes/web-components'
import { AppDataContext } from '../providers/AppData'
import useTranslateMessage from '../hooks/useTranslateMessage'
import highlightText from '../utils/highlightText'
import { fixSlug } from '../utils/checkProfileSlugRedirect'
import { useDateTimeCorrector } from '../hooks/useDatetimeCorrector'
import { ProfilePeerRecommendation } from '../types'
import { Statistic } from '../types'
import { ONLINE, SpecialistPlans } from '../constants/profile'
import getTenantName from '../utils/getTenantName'
import getImageWithWWW from '../utils/getImageWithWWW'
import Avatar from './Avatar'
import { getInitialsFromFullName } from './profiles/Intro/variants/default'
import { themedColor } from './profiles/styles'
import { useWpaTenant } from '../hooks/useWpaTenant'

type RecommendationCardProps = ProfilePeerRecommendation & {
  highlightedText?: string
  selectedKeyword: string
  statistics: Statistic[]
}

type Keyword = { name: { [x: string]: string }; id: number }

const RecommendationText = styledComponent(Typography)(({ theme }) => ({
  display: 'inline',
  '& .text-highlight': {
    color: theme.palette.primary.main
  }
}))

const RECOMMENDATION_TEXT_CHAR_LIMIT = 600

const VISIBLE_KEYWORDS_LIMIT = 3

const RELATION_TRANSLATION_LIST: Record<string, string> = {
  'Is a colleague': 'peerRecommendation.relation.colleague',
  'I trained them': 'peerRecommendation.relation.trained',
  'We trained together': 'peerRecommendation.relation.trained-together',
  'I have referred patients to them': 'peerRecommendation.relation.referred-patients',
  'I have looked after their patients': 'peerRecommendation.relation.looked-after'
}

const RecommendationCard: FC<RecommendationCardProps> = ({
  createdAt,
  recommendation,
  keywords,
  recommender,
  connection,
  highlightedText,
  selectedKeyword,
  statistics
}) => {
  const theme = useTheme()
  const { locale, language } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const {
    buttonBackground,
    endorsementsChipsBackground,
    endorsementsChipsBorder,
    endorsementsChipsSelectedBorder,
    endorsementsChipsSelectedBackground,
    endorsementsChipsTextColor
  } = themedColor()
  const { isWPA } = useWpaTenant()

  const [showFullText, setShowFullText] = useState(false)

  const recommenderFullName = useMemo(() => {
    const { title, firstName, lastName } = recommender
    const fullNameParts = [title[language], firstName[language], lastName[language]]

    return fullNameParts.filter(Boolean).join(' ')
  }, [recommender, language])

  const recommenderInitials = useMemo(() => getInitialsFromFullName(recommenderFullName), [recommenderFullName])

  const peerRecommendationKeywords = useMemo(
    () => statistics.filter((tag) => tag.tagType === 'peerRecommendation'),
    [statistics]
  )

  const getCountById = useCallback(
    (id: number) => peerRecommendationKeywords.find((tag) => tag.tagId === id)?.tagCount || 0,
    [peerRecommendationKeywords]
  )

  const sortKeywords = useCallback(
    (keywordA: Keyword, keywordB: Keyword) => {
      if (keywordA.name[language] === selectedKeyword) {
        return -1
      }
      if (keywordB.name[language] === selectedKeyword) {
        return 1
      }

      return getCountById(keywordB.id) - getCountById(keywordA.id)
    },
    [getCountById, language, selectedKeyword]
  )
  // todo: clarify deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortedKeywords = useMemo(() => keywords.sort(sortKeywords), [keywords, selectedKeyword])

  const [visibleKeywords, setVisibleKeywords] = useState<Keyword[]>(sortedKeywords.slice(0, VISIBLE_KEYWORDS_LIMIT))

  useEffect(() => {
    setVisibleKeywords(sortedKeywords.slice(0, VISIBLE_KEYWORDS_LIMIT))
  }, [sortedKeywords])

  const dateTime = useDateTimeCorrector(createdAt)

  const isOnline =
    recommender.status === ONLINE && recommender.tenants.includes(getTenantName(locale).replace('athena-', ''))

  const fullName = (
    <Typography variant="body2" color={theme.custom?.common?.searchIconColor} fontWeight={500}>
      {recommenderFullName}
    </Typography>
  )

  const toggleShowFullText = useCallback(() => setShowFullText((prev) => !prev), [])
  const expandKeywords = useCallback(() => setVisibleKeywords(sortedKeywords), [sortedKeywords])

  const isTextTruncated = recommendation.length > RECOMMENDATION_TEXT_CHAR_LIMIT

  const recommendationText = useMemo(() => {
    if (showFullText) {
      return recommendation
    }

    return `${recommendation.slice(0, RECOMMENDATION_TEXT_CHAR_LIMIT)}${isTextTruncated ? '...' : ''}`
  }, [recommendation, showFullText, isTextTruncated])

  return (
    <Card sx={{ p: 2, boxShadow: 'none', contentVisibility: 'auto', containIntrinsicHeight: '500px' }}>
      <CardContent sx={{ p: 0 }}>
        <RecommendationText
          variant="body1"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: highlightText(recommendationText, highlightedText) }}
        />
        {isTextTruncated && (
          <Typography
            variant="body2"
            display="inline"
            sx={{ cursor: 'pointer', textDecoration: 'underline', margin: '0 4px' }}
            onClick={toggleShowFullText}
          >
            {translate(showFullText ? 'read_less' : 'read_more')}
          </Typography>
        )}
        <Typography ml={1} variant="caption" color="text.disabled" mt={2}>
          {dateTime}
        </Typography>
        <Grid container alignItems="center" sx={{ mt: 2 }}>
          <Typography variant="caption" color="text.disabled" sx={{ mr: 0.5 }}>
            {translate('peerRecommendation.endorsed_for')}
          </Typography>
          {visibleKeywords.map((keyword, idx) => {
            const isSelected = keyword.name[language] === selectedKeyword

            return (
              <Chip
                key={`recommended-keyword-${keyword.name[language]}-${idx}`}
                label={
                  <Box display="flex" alignItems="center">
                    {isSelected && isWPA && <CheckboxCircle sx={{ width: '18px' }} />}
                    <Typography
                      sx={{ paddingLeft: isSelected ? '8px' : 0 }}
                      variant="caption"
                      color={endorsementsChipsTextColor}
                      fontWeight="500"
                    >
                      {keyword.name[language]}
                    </Typography>
                  </Box>
                }
                sx={{
                  mr: 0.5,
                  my: 0.25,
                  fontSize: '12px',
                  fontWeight: 500,
                  border: isSelected ? endorsementsChipsSelectedBorder : endorsementsChipsBorder,
                  backgroundColor: isSelected ? endorsementsChipsSelectedBackground : endorsementsChipsBackground,
                  '.MuiChip-label': {
                    paddingLeft: isSelected ? 0 : '8px'
                  }
                }}
                size="small"
              />
            )
          })}
          {keywords.length > VISIBLE_KEYWORDS_LIMIT && visibleKeywords.length < keywords.length && (
            <Link
              component="button"
              sx={{
                mr: 0.5,
                my: 0.25,
                fontSize: '11.85px',
                fontWeight: 500,
                cursor: 'pointer',
                color: buttonBackground
              }}
              onClick={expandKeywords}
            >
              {translate('plus_count_more', { count: keywords.length - VISIBLE_KEYWORDS_LIMIT })}
            </Link>
          )}
        </Grid>
        <Divider sx={{ my: 2 }} />
      </CardContent>
      <CardHeader
        sx={{ p: 0, '& .MuiCardHeader-content': { maxWidth: '65%' } }}
        avatar={
          <>
            {recommender.plan === SpecialistPlans.ENTRY ? (
              <Grid
                container
                width={64}
                height={64}
                justifyContent="center"
                alignContent="center"
                sx={{
                  backgroundColor: 'blue.200',
                  borderRadius: 0.5
                }}
              >
                <Typography variant="h6" component="p" fontWeight={500}>
                  {recommenderInitials}
                </Typography>
              </Grid>
            ) : (
              <Avatar
                sx={{ borderRadius: 0.5 }}
                lazy
                src={getImageWithWWW(recommender.images?.logo)}
                alt={recommenderFullName}
                width={64}
                height={64}
                withDoctifyCDNLoader
              />
            )}
          </>
        }
        title={
          isOnline ? (
            <NextLink prefetch={false} href={`/${locale}/specialist/${fixSlug(recommender.slug)}`} passHref>
              <a style={{ textDecoration: 'none' }}>{fullName}</a>
            </NextLink>
          ) : (
            fullName
          )
        }
        subheader={
          <>
            {!!recommender.specialties?.length && (
              <Typography variant="caption" color="text.disabled" sx={{ mt: 0.25, mr: 0.8 }}>
                {recommender.specialties[0].name[language]}
              </Typography>
            )}
            {!!connection && (
              <Chip
                label={
                  RELATION_TRANSLATION_LIST[connection] ? translate(RELATION_TRANSLATION_LIST[connection]) : connection
                }
                variant="outlined"
                size="small"
                sx={{ borderRadius: '4px', p: 0, textTransform: 'uppercase', mt: 0.25, fontSize: '10.53px' }}
              />
            )}
          </>
        }
      />
    </Card>
  )
}

export default RecommendationCard
