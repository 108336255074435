import React, { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { Grid, styledComponent, SxProps } from '@hermes/web-components'
import { TENANTS } from '../../../constants/global'
import RatingBar from '../../RatingBar'
import { AppDataContext } from '../../../providers/AppData'
import { SearchReviewsContext } from '../../../providers/SearchReviews'
import useStatistic from '../../../hooks/useStatistic'
import { ContactsSectionProps } from '../ContactsSection/types'

const BookingCalendarApp = dynamic(() => import('../../BookingCalendar/BookingCalendarApp'))

const ReviewBarWrapper = styledComponent(Grid)(() => ({
  position: 'relative'
}))

type RatingBarSectionProps = Pick<
  ContactsSectionProps,
  | 'endAnchorRef'
  | 'specialist'
  | 'totalPeerRecommendations'
  | 'latestPeerRecommendation'
  | 'toggleBookingModal'
  | 'latestReview'
  | 'hideBookAppointment'
  | 'practicesWithContactDetails'
  | 'isUnclaimed'
  | 'contacts'
  | 'onlineBookingAvailable'
> & {
  sx?: SxProps
  barStyles?: SxProps
  omitContainerSpacing?: boolean
}

const RatingBarSection = ({
  endAnchorRef,
  specialist,
  totalPeerRecommendations,
  latestPeerRecommendation,
  toggleBookingModal,
  latestReview,
  hideBookAppointment,
  practicesWithContactDetails,
  isUnclaimed,
  contacts,
  sx = {},
  onlineBookingAvailable = false,
  barStyles = {},
  omitContainerSpacing
}: RatingBarSectionProps) => {
  const { locale } = useRouter()

  const { language } = useContext(AppDataContext)
  const {
    state: { totalCount: reviewsTotal }
  } = useContext(SearchReviewsContext)

  const { reviewStatistic, mergedStatistic } = useStatistic(specialist.statistic)

  const bookingCalendarComponent = useMemo(
    () =>
      onlineBookingAvailable && locale === TENANTS.WPA ? <BookingCalendarApp containerStyle={{ padding: 20 }} /> : null,
    [onlineBookingAvailable, locale]
  )

  return (
    <ReviewBarWrapper sx={sx || {}} item xs={12} lg={5}>
      <RatingBar
        endAnchorRef={endAnchorRef}
        profileName={specialist.fullName?.[language]}
        isBasic={specialist.basic}
        averageRating={specialist.averageRating}
        reviewsTotal={reviewsTotal}
        peerRecommendationsCount={totalPeerRecommendations}
        peerRecommendation={latestPeerRecommendation}
        onBookAppointment={toggleBookingModal}
        statistic={mergedStatistic}
        styles={barStyles}
        reviewStatistic={reviewStatistic}
        externalIdProfile={specialist.externalId}
        slug={specialist.slug}
        latestReview={latestReview}
        review={specialist.review}
        hideBookAppointment={hideBookAppointment && !practicesWithContactDetails?.length}
        isUnclaimed={isUnclaimed}
        type={'specialist'}
        contacts={contacts}
        bookingCalendarComponent={bookingCalendarComponent}
        onlineBookingAvailable={onlineBookingAvailable}
        omitContainerSpacing={omitContainerSpacing}
      />
    </ReviewBarWrapper>
  )
}

export default RatingBarSection
