import { useMemo, useContext } from 'react'
import { SpecialistPlans } from '../constants/profile'
import { AppDataContext } from '../providers/AppData'
import useTranslateMessage from './useTranslateMessage'
import { SearchReviewsFilters } from '../providers/SearchReviews/types'
import { transformToHash } from '../utils/hash'
import { SpecialistProfile } from '../types/specialist'
import { useHrefLang } from './useHrefLang'
import { removeVideoConsultation } from '../utils/address'
import getConfigVariable from '../utils/getConfigVariable'
import { useWpaTenant } from './useWpaTenant'
import { getDefaultCountry } from '../constants/global'
import { preloadImageLoader } from '../utils/imageLoaders'

export default function useSpecialistProfileMeta(
  specialist: SpecialistProfile,
  filters?: SearchReviewsFilters,
  isReviews?: boolean
) {
  const { language, locale } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const defaultCountry = getDefaultCountry(locale)
  const hrefLangs = useHrefLang()
  const { isWPA } = useWpaTenant()
  const host = isWPA ? getConfigVariable('WPA_HOST') : getConfigVariable('HOST')
  const preloadImage = preloadImageLoader({ src: specialist.images?.logo, host })
  return useMemo(() => {
    const fullName = [specialist.title[language], specialist.fullName[language]].filter((i) => i?.length > 1).join(' ')
    const topLevelKeyword = specialist?.keywords.filter(
      (keyword) => keyword.isTopLevelChild && keyword.keywordType === 'specialty'
    )?.[0]
    const mainPractice = specialist.practices?.filter(removeVideoConsultation)[0]
    const city = mainPractice?.address?.city?.[language] || defaultCountry
    const isBasic = specialist.basic || specialist.plan.includes(SpecialistPlans.UNCLAIMED)
    const fixedSlug = specialist.slug.replace(/_/g, '-')
    const canonicalTransformedToHash = transformToHash({
      search: filters?.search,
      keyword: filters?.keyword,
      sort: filters?.sort
    })
    const canonicalHash = canonicalTransformedToHash ? `#${canonicalTransformedToHash}` : ''

    return {
      preloadImage,
      profileFirstName: specialist.firstName[language],
      profileLastName: specialist.lastName[language],
      profileSlug: specialist.slug,
      profileGender: specialist.gender,
      title: isReviews
        ? (translate('profile.specialist.meta.titleReviews', {
            name: fullName
          }) as string)
        : (translate('profile.specialist.meta.title', {
            name: fullName,
            keyword: topLevelKeyword?.practitioner?.[language] || topLevelKeyword?.name?.[language],
            city
          }) as string),
      description: isReviews
        ? (translate(
            isBasic ? 'profile.specialist.meta.description.basic' : 'profile.specialist.meta.description.review',
            {
              name: fullName,
              specialty: topLevelKeyword?.name?.[language],
              reviews: specialist.reviewsTotal
            }
          ) as string)
        : (translate(isBasic ? 'profile.specialist.meta.description.basic' : 'profile.specialist.meta.description', {
            name: fullName,
            specialty: topLevelKeyword?.name?.[language]
          }) as string),
      canonicalUrl: `${host}/${locale}/specialist/${fixedSlug}${
        filters?.page ? `/reviews/page-${filters.page}${canonicalHash}` : ''
      }`,
      language,
      hrefLangs,
      noindex: specialist.demo
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialist, defaultCountry, filters?.page, filters?.search, filters?.keyword, filters?.sort])
}
