import React, { useContext, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Grid,
  Typography,
  styledComponent,
  styled,
  Hidden,
  Box,
  Lazy,
  ArrowForwardIcon,
  useTheme,
  useMediaQuery,
  Rating,
  InView
} from '@hermes/web-components'
import dynamic from 'next/dynamic'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { Marker as MarkerType } from '../../../types/map'
import { transformAddressToString } from '../../../utils/address'
import { AppDataContext } from '../../../providers/AppData'
import { fixSlug } from '../../../utils/checkProfileSlugRedirect'
import { SpecialistPractice } from '../../../types/specialist'
import Expand from '../../Icons/Expand'
import { DetailsGrid, Divider, HoursContainer, ExpandIconContainer } from './styles'
import { useWpaTenant } from '../../../hooks/useWpaTenant'
import { inactiveMarkerIndicator, wpaMarkerIndicator } from '../../../constants/global'

const Marker = dynamic(() => import('@react-google-maps/api').then((module) => module.Marker), { ssr: false })
const GoogleMap = dynamic(() => import('../../GoogleMap'), { ssr: false })

type PracticeLocationCardProps = Pick<
  SpecialistPractice,
  'id' | 'slug' | 'name' | 'address' | 'reviews' | 'workingOpeningHours'
> & {
  activeLocation: string | number
  onSelect: (val: string | number) => void
  previewImage?: string
  isPremium?: boolean
  openMap: (marker?: MarkerType) => void
  onGetInTouch?: () => void
  hideBookAppointment?: boolean
  disableNavigation?: boolean
}

const CardWrapper = styledComponent(Box, {
  shouldForwardProp: (prop: string) => !['active'].includes(prop)
})<{ active: boolean }>(({ theme, active }) => ({
  display: 'flex',
  gap: '2rem',
  padding: theme.spacing(2.25, 2),
  cursor: 'pointer',
  color: active ? theme.palette.primary.main : theme.palette.text.disabled,
  fontWeight: 500,
  background: active ? theme.palette.background.default : 'initial',
  boxShadow: active ? '0 4px 5px #0203030A' : 'initail',
  '&:hover': {
    background: theme.palette.background.default,
    boxShadow:
      '0px 3px 5px 0px rgba(2, 3, 3, 0.04), 0px 1px 18px 0px rgba(2, 3, 3, 0.02), 0px 6px 10px 0px rgba(2, 3, 3, 0.03)',
    color: theme.palette.primary.main
  },
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  [theme.breakpoints.down('lg')]: {
    display: 'block'
  }
}))

const CardContent = styledComponent(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto'
  }
}))

const CardName = styled('a')(() => ({
  textTransform: 'none',
  textDecoration: 'none',
  fontWeight: 'inherit',
  color: 'inherit'
}))

const MapPreviewWrapper = styledComponent(Grid)(({ theme }) => ({
  borderRadius: 4,
  marginRight: theme.spacing(1),
  height: 96,
  width: 72
}))

const workingDays: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
}

function PracticeLocationCard({
  id,
  name,
  address,
  slug,
  reviews,
  onSelect,
  activeLocation,
  isPremium,
  previewImage,
  openMap,
  onGetInTouch,
  hideBookAppointment,
  workingOpeningHours = {},
  disableNavigation
}: PracticeLocationCardProps) {
  const router = useRouter()
  const translate = useTranslateMessage()
  const { language } = useContext(AppDataContext)
  const theme = useTheme()
  const { isWPA } = useWpaTenant()
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [elementVisible, setElementVisibility] = useState(false)

  if (!address?.geolocation && slug !== 'video-consultation') {
    return null
  }

  const onChangeElementVisibility = (val: boolean) => {
    if (val) {
      setElementVisibility(val)
    }
  }

  const isActive = id === activeLocation
  const onClick = () => onSelect(id)
  const onOpen = () => openMap({ position: { ...address.geolocation } })

  return (
    <CardWrapper active={isActive} onClick={onClick}>
      <DetailsGrid>
        {isPremium && isWPA && (
          <svg style={{ width: '106px', height: '28px', ...theme.custom.practiceLocationCardBadge }}>
            <use xlinkHref="#premium-badge-lined-icon" />
          </svg>
        )}
        <Grid sx={{ margin: 0, width: '100%' }} container wrap="nowrap" spacing={1}>
          {slug !== 'video-consultation' && (
            <Lazy onChange={onChangeElementVisibility} sx={{ alignContent: 'center' }}>
              <MapPreviewWrapper item onClick={onOpen} sx={{ position: 'relative' }}>
                <Hidden lgDown>
                  <ExpandIconContainer>
                    <Expand />
                  </ExpandIconContainer>
                </Hidden>
                {elementVisible && !previewImage && (
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={{
                      lat: address.geolocation.lat,
                      lng: address.geolocation.lon
                    }}
                    options={{
                      fullscreenControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      panControl: false,
                      scrollwheel: false,
                      zoom: 12
                    }}
                  >
                    <InView>
                      {({ inView, ref }) => (
                        <div ref={ref}>
                          {inView && (
                            <Marker
                              position={{
                                lat: address.geolocation.lat,
                                lng: address.geolocation.lon
                              }}
                              icon={isWPA ? wpaMarkerIndicator : inactiveMarkerIndicator}
                            />
                          )}
                        </div>
                      )}
                    </InView>
                  </GoogleMap>
                )}
                {!!previewImage && <img src={previewImage} alt="map" width="100%" height="100%" />}
              </MapPreviewWrapper>
            </Lazy>
          )}
          <CardContent item>
            <Grid container justifyContent="space-between">
              <Typography gutterBottom variant="body2" fontWeight="600">
                {disableNavigation || name[language] === 'Video Consultation' ? (
                  <CardName>{name[language]}</CardName>
                ) : (
                  <Link prefetch={false} href={`/${router.locale}/practice/${fixSlug(slug)}`} passHref color="inherit">
                    <CardName>{name[language]}</CardName>
                  </Link>
                )}
              </Typography>
              <Hidden lgUp>
                <ArrowForwardIcon
                  sx={{
                    display: 'none',
                    fontSize: '16px',
                    ...(isActive ? { transform: 'rotate(90deg)', display: 'block' } : {})
                  }}
                />
              </Hidden>
            </Grid>
            <Grid container alignItems="center" wrap="nowrap" sx={{ mb: 1.5 }}>
              <Rating value={reviews.overallExperience} size="xs" readOnly />
              <Typography
                sx={{ ...theme.custom.locationCard, ml: 0.5, mr: 1 }}
                variant="caption"
                fontWeight="600"
                color="text.secondary"
              >
                {reviews.overallExperience}
              </Typography>
              <Typography
                variant="subtitle2"
                component="p"
                color="text.secondary"
              >{`(${reviews.reviewsTotal})`}</Typography>
            </Grid>
            <Typography
              variant="subtitle2"
              color="text.disabled"
              component="p"
              sx={{
                mt: isLgDown ? '0.5rem' : 1
              }}
              textTransform="capitalize"
            >
              {transformAddressToString(address, language)}
            </Typography>
          </CardContent>
        </Grid>
      </DetailsGrid>
      <Divider isActive={isActive} />
      <HoursContainer isActive={isActive}>
        <Lazy
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderTopWidth: '1px'
          }}
        >
          {Object.keys(workingDays).map((workingDay) => {
            const dayIndex = workingDays[workingDay]
            const isAvailable = dayIndex && workingOpeningHours?.[dayIndex]?.length
            return (
              <Grid
                justifyContent="space-between"
                key={`day-${workingDay}`}
                gap={2}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <Typography variant="caption" color="text.primary" fontWeight="600" textTransform="capitalize">
                  {translate(workingDay)}
                </Typography>
                <div
                  style={{
                    display: 'grid',
                    textAlign: 'end'
                  }}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isAvailable ? (
                    workingOpeningHours[dayIndex].map((hours) => {
                      const workingTime = `${hours.open} - ${hours.close}`
                      return (
                        <Typography key={`${workingDay}-${workingTime}`} variant="caption" color="text.primary">
                          {workingTime}
                        </Typography>
                      )
                    })
                  ) : hideBookAppointment ? (
                    <Typography color="text.primary" variant="caption">
                      {translate('not_available')}
                    </Typography>
                  ) : (
                    <Typography
                      color="text.primary"
                      variant="caption"
                      sx={isWPA ? {} : { textDecoration: 'underline' }}
                      onClick={onGetInTouch}
                    >
                      {translate('global.enquire')}
                    </Typography>
                  )}
                </div>
              </Grid>
            )
          })}
        </Lazy>
      </HoursContainer>
    </CardWrapper>
  )
}

export default PracticeLocationCard
