import React, { useContext } from 'react'
import { Grid, Hidden, styledComponent, useTheme } from '@hermes/web-components'
import SectionWrapper from '../../profiles/SectionWrapper'
import ProfileIntro from '../../profiles/Intro'
import ProfileTabs from '../../profiles/ProfileTabs'
import { ContactsSectionProps } from './types'
import RatingBarSection from '../RatingBarSection'
import { AppDataContext } from '../../../providers/AppData'

const IntroSectionWrapper = styledComponent(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(13),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(18)
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(18)
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(15)
  }
}))

const Desktop = ({
  address,
  endAnchorRef,
  specialist,
  isUnclaimed,
  specialistTitle,
  specialistSuffix,
  isEntry,
  introVideoAvailable,
  totalPeerRecommendations,
  profileAnalytics,
  setVideoPlayerVisibility,
  onlineBookingAvailable,
  latestPeerRecommendation,
  latestReview,
  contacts,
  practicesWithContactDetails,
  recommendersString,
  toggleBookingModal
}: ContactsSectionProps & { recommendersString: string }) => {
  const { language } = useContext(AppDataContext)
  const theme = useTheme()

  return (
    <>
      <Hidden smDown implementation="css">
        <SectionWrapper
          sx={{
            backgroundColor: theme.custom?.SpecialistPage?.common?.background ?? {
              xs: 'common.white',
              lg: 'background.default'
            }
          }}
          id="overview"
        >
          <IntroSectionWrapper container>
            <Grid item xs={12} lg={7}>
              <ProfileIntro
                id={specialist.externalId}
                address={address}
                slug={specialist.slug}
                plan={specialist.plan}
                isBasic={specialist.basic}
                feeAgreed={specialist?.customFields?.feeAgreed}
                name={specialist.fullName?.[language]}
                title={specialistTitle}
                suffix={specialistSuffix}
                image={specialist.images?.logo}
                keywords={specialist.keywords}
                yearsOfExperience={specialist.yearsOfExperience}
                practices={specialist.practices}
                isUnclaimed={isUnclaimed}
                isEntry={isEntry}
                videoConsultation={specialist.videoConsultation}
                introVideoAvailable={introVideoAvailable}
                profileAnalytics={profileAnalytics}
                setVideoPlayerVisibility={setVideoPlayerVisibility}
                connectionsCount={specialist.connectionsCount}
                isBookingAvailable={onlineBookingAvailable}
                review={specialist.review}
                recommendersString={recommendersString}
                toggleBookingModal={toggleBookingModal}
              />
            </Grid>
            <RatingBarSection
              endAnchorRef={endAnchorRef}
              specialist={specialist}
              totalPeerRecommendations={totalPeerRecommendations}
              latestPeerRecommendation={latestPeerRecommendation}
              toggleBookingModal={toggleBookingModal}
              latestReview={latestReview}
              practicesWithContactDetails={practicesWithContactDetails}
              isUnclaimed={isUnclaimed}
              contacts={contacts}
              onlineBookingAvailable={onlineBookingAvailable}
            />
          </IntroSectionWrapper>
        </SectionWrapper>
      </Hidden>
      <ProfileTabs smDown implementation="css" specialist={specialist} isUnclaimed={isUnclaimed} />
    </>
  )
}

export default Desktop
