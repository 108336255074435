import React, { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { Grid, Hidden, useTheme } from '@hermes/web-components'
import ProfileIntro from '../../profiles/Intro'
import ProfileTabs from '../../profiles/ProfileTabs'
import { TENANTS } from '../../../constants/global'
import { ContactsSectionProps } from './types'
import RatingBarSection from '../RatingBarSection'
import { AppDataContext } from '../../../providers/AppData'

const BookingCalendarApp = dynamic(() => import('../../BookingCalendar/BookingCalendarApp'))

const Mobile = ({
  address,
  endAnchorRef,
  specialist,
  isUnclaimed,
  specialistTitle,
  specialistSuffix,
  isEntry,
  introVideoAvailable,
  totalPeerRecommendations,
  profileAnalytics,
  setVideoPlayerVisibility,
  onlineBookingAvailable,
  latestPeerRecommendation,
  latestReview,
  contacts,
  practicesWithContactDetails,
  recommendersString,
  toggleBookingModal,
  hideBookAppointment
}: ContactsSectionProps & { recommendersString: string }) => {
  const { language } = useContext(AppDataContext)
  const { locale } = useRouter()
  const theme = useTheme()

  const hasContent = useMemo(
    () => Boolean(totalPeerRecommendations || latestPeerRecommendation || latestReview),
    [latestPeerRecommendation, latestReview, totalPeerRecommendations]
  )

  return (
    <>
      <ProfileTabs smUp implementation="css" specialist={specialist} isUnclaimed={isUnclaimed} />
      <Hidden smUp implementation="css">
        <Grid
          item
          sx={{
            p: '70px 16px 0 16px',
            backgroundColor: theme.custom?.SpecialistPage?.common?.background ?? 'common.white'
          }}
          xs={12}
          lg={7}
        >
          <ProfileIntro
            id={specialist.externalId}
            address={address}
            slug={specialist.slug}
            plan={specialist.plan}
            isBasic={specialist.basic}
            feeAgreed={specialist?.customFields?.feeAgreed}
            name={specialist.fullName?.[language]}
            title={specialistTitle}
            suffix={specialistSuffix}
            image={specialist.images?.logo}
            keywords={specialist.keywords}
            yearsOfExperience={specialist.yearsOfExperience}
            practices={specialist.practices}
            isUnclaimed={isUnclaimed}
            isEntry={isEntry}
            videoConsultation={specialist.videoConsultation}
            introVideoAvailable={introVideoAvailable}
            profileAnalytics={profileAnalytics}
            setVideoPlayerVisibility={setVideoPlayerVisibility}
            connectionsCount={specialist.connectionsCount}
            isBookingAvailable={onlineBookingAvailable}
            review={specialist.review}
            recommendersString={recommendersString}
            toggleBookingModal={toggleBookingModal}
          />
        </Grid>
        <RatingBarSection
          endAnchorRef={endAnchorRef}
          specialist={specialist}
          totalPeerRecommendations={totalPeerRecommendations}
          latestPeerRecommendation={latestPeerRecommendation}
          toggleBookingModal={toggleBookingModal}
          latestReview={latestReview}
          hideBookAppointment={hideBookAppointment}
          practicesWithContactDetails={practicesWithContactDetails}
          isUnclaimed={isUnclaimed}
          contacts={contacts}
          onlineBookingAvailable={onlineBookingAvailable}
          sx={{ pl: '16px !important', marginBottom: hasContent || locale === TENANTS.WPA ? 0 : '-40px' }}
          omitContainerSpacing={!hasContent && locale !== TENANTS.WPA}
          barStyles={theme.custom.SpecialistPage.RatingBarStyles}
        />
        {onlineBookingAvailable && locale === TENANTS.WPA ? (
          <BookingCalendarApp
            containerStyle={{ padding: '20px', backgroundColor: 'white', justifyContent: 'center' }}
          />
        ) : null}
      </Hidden>
    </>
  )
}

export default Mobile
